import { H1, P } from "@/components/ui/typography.tsx"

export function Error500Page() {
  return (
    <>
      <H1>ERROR 500</H1>
      <P className="text-balance">
        We apologize, an unexpected error has occurred. There might be a temporary issue with one of
        our systems. Could you please try again later.
      </P>
    </>
  )
}
