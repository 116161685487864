import { InputField } from "@/components/hook-form/input-field.tsx"
import { TextareaField } from "@/components/hook-form/textarea-field.tsx"
import { KnowledgeSelector } from "@/components/knowledge-selector.tsx"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { Form, FormField } from "@/components/ui/form.tsx"
import { RouterOutput, trpc } from "@/lib/trpc.ts"
import { ROOT_ROUTES } from "@/routes.tsx"
import { ErrorMessage } from "@hookform/error-message"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { z } from "zod"
import { SwitchField } from "./hook-form/switch-field.tsx"

type ChatBot = NonNullable<RouterOutput["chatbots"]["get"]>

export type ChatBotFormProps = {
  chatBot?: ChatBot
  actions: (state: { isSubmitting: boolean; isLoading: boolean }) => React.ReactNode
  onSubmit: (values: z.infer<typeof formSchema>) => any
}

const formSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1, {
      message: "Please provide a name.",
    }),
    advancedReasoning: z.boolean(),
    memory: z.boolean(),
    description: z.string().min(1, {
      message: "Please provide a description.",
    }),
    knowledge: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        description: z.string(),
      }),
    ),
    emailWhitelist: z.string(),
    role: z.string().min(1, {
      message: "Please provide a role.",
    }),
    context: z.string(),
    rules: z.string(),
    openingMessage: z.string(),
    returnMessage: z.string(),
    userMessageHandling: z.string(),
    slug: z
      .string()
      .regex(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, {
        message: "Slug must only have alphabetic characters and hyphens",
      })
      .min(3, {
        message: "Slug needs to be minimum 3 characters",
      })
      .max(30, {
        message: "Slug needs to be a maximum of 30 characters",
      }),
  })
  .superRefine(async (data, ctx) => {
    if (data.slug) {
      if (ROOT_ROUTES.includes(data.slug)) {
        ctx.addIssue({
          code: "custom",
          path: ["slug"],
          message: "The slug is reserved, please change it",
        })
        return
      }
      const validationResult = await trpc.chatbots.validateSlug.query({
        slug: data.slug,
        chatBotId: data.id,
      })
      if (!validationResult.valid) {
        switch (validationResult.reason) {
          case "invalid":
            ctx.addIssue({
              code: "custom",
              path: ["slug"],
              message: "Slug is invalid",
            })
            break
          case "exists":
            ctx.addIssue({
              code: "custom",
              path: ["slug"],
              message: "Slug is already being used by another ChatBot",
            })
            break
        }
      }
    }
  })

function createSlug(input: string): string {
  return input
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading/trailing whitespace
    .replace(/[^a-z0-9\s-]/g, "") // Remove invalid characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-") // Replace multiple hyphens with a single hyphen
}

export function ChatBotForm(props: ChatBotFormProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema, {
      async: true,
    }),
    defaultValues: {
      id: props.chatBot?.id,
      name: props.chatBot?.name,
      description: props.chatBot?.description,
      advancedReasoning: !!props.chatBot?.advancedReasoning,
      memory: !!props.chatBot?.memory,
      knowledge: props.chatBot?.knowledge ?? [],
      emailWhitelist:
        props.chatBot?.emailWhitelist ??
        "@gsmsystems.com,@gsmsystems.net,@netlifesaas.com,@netlife.co",
      role: props.chatBot?.role ?? "",
      context: props.chatBot?.context ?? "",
      rules: props.chatBot?.rules ?? "",
      openingMessage: props.chatBot?.openingMessage ?? "",
      returnMessage: props.chatBot?.returnMessage ?? "",
      userMessageHandling: props.chatBot?.userMessageHandling ?? "",
      slug: props.chatBot?.slug ?? "",
    },
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(props.onSubmit)}>
        <div className="grid gap-6">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <Card>
              <CardHeader>
                <CardTitle>Description</CardTitle>
              </CardHeader>
              <CardContent className="grid gap-6">
                <div className="grid gap-3">
                  <FormField
                    control={form.control}
                    name="name"
                    disabled={form.formState.isSubmitting || form.formState.isLoading}
                    render={({ field }) => (
                      <InputField
                        label="Name"
                        description="Try to keep the name short and simple, for example 'Sales Assistant' or 'Technical Support'."
                        field={field}
                        onBlur={() => {
                          const name = form.getValues("name")
                          const slug = form.getValues("slug")
                          if (name && !slug) {
                            form.setValue("slug", createSlug(name))
                          }
                        }}
                      />
                    )}
                  />
                </div>
                <div className="grid gap-3">
                  <FormField
                    control={form.control}
                    name="description"
                    disabled={form.formState.isSubmitting || form.formState.isLoading}
                    render={({ field }) => (
                      <TextareaField
                        label="Description"
                        description="A high-level description for your ChatBot to help users
                  quickly understand the purpose of the ChatBot."
                        field={field}
                      />
                    )}
                  />
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Capabilities</CardTitle>
              </CardHeader>
              <CardContent className="grid gap-6">
                <div className="grid gap-6">
                  <FormField
                    control={form.control}
                    name="advancedReasoning"
                    disabled={form.formState.isSubmitting || form.formState.isLoading}
                    render={({ field }) => (
                      <SwitchField
                        label="Advanced Reasoning"
                        description="Enabling advanced reasoning will allow the ChatBot to provide more complex and detailed responses. It will also be able to perform more advanced data analysis. This comes at the the expense of speed and cost. We suggest leaving this disabled initially. Analyze the responses of your ChatBot and then only come back and enable this."
                        field={field}
                      />
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="memory"
                    disabled={form.formState.isSubmitting || form.formState.isLoading}
                    render={({ field }) => (
                      <SwitchField
                        label="Conversation Memory"
                        description="Enabling this will allow the ChatBot to continue a conversation with a user across multiple sessions. They will not be presented with the initial opening message on subsequent visits. Note that there is an underlying limit of the ChatBots memory. When the limit is hit the ChatBot will attempt to hold onto the 'important' aspects of memory to help it in future conversations."
                        field={field}
                      />
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <Card>
              <CardHeader>
                <CardTitle>Knowledge</CardTitle>
                <CardDescription>
                  Select the Knowledge you wish to make available to this Chatbot.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Controller
                  control={form.control}
                  name="knowledge"
                  render={({ field }) => (
                    <>
                      <KnowledgeSelector onChange={field.onChange} value={field.value} />
                      <div className="text-destructive text-sm">
                        <ErrorMessage errors={form.formState.errors} name="knowledge" />
                      </div>
                    </>
                  )}
                />
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Access</CardTitle>
                <CardDescription>Control access to the Chat Bot.</CardDescription>
              </CardHeader>
              <CardContent className="grid gap-6">
                <div className="grid gap-3">
                  <FormField
                    control={form.control}
                    name="slug"
                    disabled={form.formState.isSubmitting || form.formState.isLoading}
                    render={({ field }) => (
                      <InputField
                        label="Slug"
                        description="A unique URL path identifier for the ChatBot. e.g. 'infinity', or  'tech-assistant'. This enables easier sharing of a ChatBot"
                        field={field}
                      />
                    )}
                  />
                </div>
                <div className="grid gap-3">
                  <FormField
                    control={form.control}
                    name="emailWhitelist"
                    disabled={form.formState.isSubmitting || form.formState.isLoading}
                    render={({ field }) => (
                      <TextareaField
                        label="Email Whitelist"
                        description="A comma seperated list of the email address domains (e.g. '@example.com') or specific email addresses (e.g. 'john@example.com') that will have access to this ChatBot."
                        field={field}
                      />
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </div>

          <Card>
            <CardHeader>
              <CardTitle>Definition</CardTitle>
              <CardDescription>
                This is where you define the role and behaviour of the Chat Bot.
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-6">
              <FormField
                control={form.control}
                name="role"
                disabled={form.formState.isSubmitting || form.formState.isLoading}
                render={({ field }) => (
                  <TextareaField
                    label="Role"
                    description="Give instructions to the ChatBot, telling it what its role is. Tell it the type of questions it should be answering, and give it guidance on how it should be responding. For example, you can say 'You are a Sales Assistant ChatBot that will receive questions from our internal staff. You should help the sales staff access information to help them determine effective pricing for parts'. If you have exposed Knowledge to the ChatBot you can encourage it to use the Knowledge appropriately. Try to be as explicit as possible."
                    className="min-h-48 font-mono"
                    field={field}
                  />
                )}
              />
              <FormField
                control={form.control}
                name="context"
                disabled={form.formState.isSubmitting || form.formState.isLoading}
                render={({ field }) => (
                  <TextareaField
                    label="Contextual Information (Optional)"
                    description="If there is a wider set of contextual information that the Chatbot should be aware of, you can provide it here. This is not instructions on how the ChatBot should behave, but additional information that will help the ChatBot contextualize its operation. You might provide an outline of specific procedures or historical information around the domain that the ChatBot is operating against."
                    className="min-h-48 font-mono"
                    field={field}
                  />
                )}
              />
              <FormField
                control={form.control}
                name="rules"
                disabled={form.formState.isSubmitting || form.formState.isLoading}
                render={({ field }) => (
                  <TextareaField
                    label="Rules (Optional)"
                    description="In this section you should define the things that the ChatBot SHOULD NOT DO, and also the things that it SHOULD DO. For example, encourage it to always answer in a specific manner, or to always answer in a short and concise manner."
                    className="min-h-48 font-mono"
                    field={field}
                  />
                )}
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>User Interaction (Optional)</CardTitle>
              <CardDescription>
                Define the initial interaction of messages that the ChatBot will have with users.
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-6">
              <FormField
                control={form.control}
                name="openingMessage"
                disabled={form.formState.isSubmitting || form.formState.isLoading}
                render={({ field }) => (
                  <TextareaField
                    label="Opening Message to User (Optional)"
                    description="When a user first interacts with a ChatBot this is the first message that will be displayed to them."
                    className="min-h-48 font-mono"
                    field={field}
                  />
                )}
              />
              <FormField
                control={form.control}
                name="userMessageHandling"
                disabled={form.formState.isSubmitting || form.formState.isLoading}
                render={({ field }) => (
                  <TextareaField
                    label="User Response Handling (Optional)"
                    description="You can define additional explicit behaviour on how the ChatBot should handle the users response(s)."
                    className="min-h-48 font-mono"
                    field={field}
                  />
                )}
              />
              <FormField
                control={form.control}
                name="returnMessage"
                disabled={form.formState.isSubmitting || form.formState.isLoading}
                render={({ field }) => (
                  <TextareaField
                    label="Returning User message (Optional)"
                    description="When a user returns to a ChatBot that has memory enabled then the below message will be displayed to them. If this is not specified a simpled default message is displayed."
                    className="min-h-48 font-mono"
                    field={field}
                  />
                )}
              />
            </CardContent>
          </Card>

          {props.actions(form.formState)}
        </div>
      </form>
    </Form>
  )
}
