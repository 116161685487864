import { RequestAccessForm } from "@/components/request-access-form.tsx"
import { Button } from "@/components/ui/button.tsx"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet.tsx"
import { AnalyticsProvider } from "@/context/analytics.tsx"
import { Session, useAuth, useLogout } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import { cn } from "@/lib/tw-utils.ts"
import {
  Book,
  Bot,
  CircleUser,
  FileText,
  FlaskConical,
  Menu,
  MessageCircle,
  MessageCircleQuestion,
  Users,
} from "lucide-react"
import React from "react"
import { Link, NavLink, Outlet, useLocation } from "react-router-dom"

export function AppLayout() {
  const { session } = useAuth()
  const logout = useLogout()
  const [chatBots, setChatBots] = React.useState<ChatBotsResult | undefined>(undefined)

  React.useEffect(() => {
    const fetchChatBots = async () => {
      try {
        const chatBots = await trpc.chatbots.list.query()
        setChatBots(chatBots)
      } catch (err) {
        // TODO: Add a more robust error handler, with explicit check for an invalid session
        console.error(err)
        logout()
      }
    }
    if (!session) {
      window.location.href = "/auth/login?redirect=" + encodeURIComponent(window.location.pathname)
    } else {
      fetchChatBots()
    }
  }, [session])

  const contents =
    !session || chatBots === undefined ? (
      <div className="flex h-screen items-center justify-center">Loading...</div>
    ) : chatBots.length === 0 && session.account.role === "user" ? (
      <div className="h-full max-h-full w-full max-w-full">
        <main className="relative h-full max-h-full overflow-auto">
          <div className="h-full w-full pt-10">
            <RequestAccessForm />
          </div>
        </main>
      </div>
    ) : (
      <HasAccessLayout chatBots={chatBots} session={session} />
    )

  return <AnalyticsProvider>{contents}</AnalyticsProvider>
}

type ChatBotsResult = Awaited<ReturnType<typeof trpc.chatbots.list.query>>

export type AppOutletContext = {
  setTitle: (title: string) => void
  mode: "simple" | "complex"
}

function HasAccessLayout(props: { chatBots: ChatBotsResult; session: Session }) {
  const logout = useLogout()
  const [_title, setTitle] = React.useState<string>("NetLife AI")
  const [openSheetNav, setOpenSheetNav] = React.useState(false)
  const location = useLocation()

  React.useEffect(() => {
    setOpenSheetNav(false)
  }, [location])

  const nav = (
    <nav className={cn(`relative grid items-start text-sm font-medium`)}>
      <h2 className="text-foreground px-5 py-2 font-bold transition-all">Chat Bots</h2>
      {props.chatBots.map((chatBot) => (
        <AppLink
          key={chatBot.id}
          icon={<MessageCircle className="h-4 w-4" />}
          url={`/chat/${chatBot.slug ?? chatBot.id}`}
        >
          {chatBot.name}
        </AppLink>
      ))}
      {props.session.account.role === "admin" && (
        <>
          <Seperator />
          <h2 className="text-foreground my-0 px-5 py-2 font-bold transition-all">
            Administration
          </h2>
          <AppLink icon={<Bot className="h-4 w-4" />} url="/admin/chatbots">
            Chat Bots
          </AppLink>
          <AppLink icon={<Book className="h-4 w-4" />} url="/admin/knowledge">
            Knowledge
          </AppLink>
          <AppLink icon={<FileText className="h-4 w-4" />} url="/admin/files">
            Files
          </AppLink>
          <AppLink icon=<Users className="h-4 w-4" /> url="/admin/users">
            Users
          </AppLink>
          <AppLink icon=<FlaskConical className="h-4 w-4" /> url="/admin/prototype">
            Prototype
          </AppLink>
        </>
      )}
    </nav>
  )
  return (
    <div className="grid h-full max-h-full w-full max-w-full xl:grid-cols-[280px_1fr]">
      {/* Sidebar */}
      <div className="hidden border-r xl:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-14 items-center border-b px-4">
            <Link to="/" className="flex cursor-pointer items-center gap-2 font-semibold">
              <img src="/favicon.svg" alt="Logo" className="h-6 w-6" />
              <span>NetLife AI</span>
            </Link>
          </div>
          <div className="flex-1">{nav}</div>
        </div>
      </div>
      {/* Main */}
      <main className="relative h-full max-h-full overflow-auto">
        <div className="h-full w-full">
          <Outlet context={{ setTitle, mode: "complex" } satisfies AppOutletContext} />
        </div>
        <header className="fixed left-0 top-0 flex h-14 w-full items-center gap-4 px-4 pr-8 xl:left-[280px] xl:w-[calc(100%-280px)]">
          <Sheet open={openSheetNav} onOpenChange={setOpenSheetNav}>
            <SheetTrigger asChild>
              <Button variant="outline" size="icon" className="shrink-0 xl:hidden">
                <Menu className="h-5 w-5" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="flex flex-col p-0">
              <Link
                to="/"
                className="border-b-muted flex h-[60px] items-center gap-2 border-b border-solid px-5 font-semibold"
              >
                <img src="/favicon.svg" className="h-6 w-6" />
                <span className="">NetLife AI</span>
              </Link>
              {nav}
            </SheetContent>
          </Sheet>
          <div className="w-full flex-1" />
          <Button
            variant="secondary"
            size="icon"
            className="rounded-full"
            title="WhatsApp Support Chat"
            asChild
          >
            <a href="https://chat.whatsapp.com/H3WMFwSmXyY3XYVFkuNE0o" target="_blank">
              <MessageCircleQuestion className="h-5 w-5" />
              <span className="sr-only">WhatsApp Support Chat</span>
            </a>
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full" title="User Menu">
                <CircleUser className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>
                <Button type="button" variant="ghost" onClick={logout}>
                  Logout
                </Button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </header>
      </main>
    </div>
  )
}

type AppLinkProps = {
  icon: JSX.Element
  url: string
  children: React.ReactNode
}

function AppLink({ icon, url, children }: AppLinkProps) {
  return (
    <NavLink
      to={url}
      className={({ isActive, isPending }) =>
        cn(
          "text-muted-foreground hover:text-primary rounded-lg px-5 py-2 transition-all",
          (isPending || isActive) && "text-primary",
        )
      }
    >
      <span className="relative flex flex-row items-center">
        <span className="w-6">{icon}</span>
        <span className="w-[210px] truncate">{children}</span>
      </span>
    </NavLink>
  )
}

function Seperator() {
  return (
    <div className="relative py-4 font-medium">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="border-muted w-full border-t" />
      </div>
    </div>
  )
}
