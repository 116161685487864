import { Section } from "@/components/ui/page-layout.tsx"
import { Seperator } from "@/components/ui/seperator.tsx"
import { H1, H2, InlineCode, Lead, P, Ul } from "@/components/ui/typography.tsx"

export function TermsOfServicePage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <H1>Terms of Service</H1>
      <Lead>Effective Date: 2024/10/15</Lead>

      <Seperator />

      <Section>
        <H2>1. Acceptance of Terms</H2>
        <P>
          By accessing and using the NetLife AI platform (<InlineCode>the "Service"</InlineCode>),
          you agree to comply with and be bound by these Terms of Service (
          <InlineCode>"Terms"</InlineCode>). If you do not agree to these Terms, please do not use
          the Service.
        </P>
      </Section>

      <Section>
        <H2>2. Description of Service</H2>
        <P>
          NetLife AI provides AI-powered chatbots to empower the circular market economy. The
          chatbots are integrated into our system and provide insights and guidance to our clients.
        </P>
      </Section>

      <Section>
        <H2>3. User Accounts</H2>
        <P>
          Users access the Service by logging in with their email address. No other personally
          identifiable information is required. Access to certain features may be based on your user
          account and associated email domain.
        </P>
      </Section>

      <Section>
        <H2>4. User Responsibilities</H2>
        <Ul className="list-inside list-disc space-y-1">
          <li>You are responsible for maintaining the confidentiality of your account.</li>
          <li>You agree not to misuse the Service or help anyone else to do so.</li>
        </Ul>
      </Section>

      <Section>
        <H2>5. Intellectual Property</H2>
        <P>
          All content provided on the Service is the property of NetLife and is protected by
          applicable intellectual property laws. Unauthorized use of any content is prohibited.
        </P>
      </Section>

      <Section>
        <H2>6. Privacy Policy</H2>
        <P>
          Your use of the Service is also governed by our Privacy Policy, which can be found{" "}
          <a href="/privacy-policy" className="text-blue-600 underline">
            here
          </a>
          .
        </P>
      </Section>

      <Section>
        <H2>7. Termination</H2>
        <P>
          We reserve the right to suspend or terminate your access to the Service at our sole
          discretion, without prior notice or liability, for any reason whatsoever.
        </P>
      </Section>

      <Section>
        <H2>8. Limitation of Liability</H2>
        <P>
          In no event shall NetLife be liable for any indirect, incidental, special, consequential,
          or punitive damages arising out of your use of the Service.
        </P>
      </Section>

      <Section>
        <H2>9. Changes to the Terms</H2>
        <P>
          We may modify these Terms at any time. We will notify you of any changes by updating the
          date at the top of these Terms. Your continued use of the Service constitutes acceptance
          of the modified Terms.
        </P>
      </Section>

      <Section>
        <H2>10. Contact Information</H2>
        <P>
          If you have any questions about these Terms, please contact us at{" "}
          <InlineCode>support@netlife.ai</InlineCode>.
        </P>
      </Section>
    </div>
  )
}
