/**
 * Safely extracts error details from an error object.
 *
 * Use this against the `err` you receive in a try-catch block to get a string representation of the error.
 *
 * You won't _always_ receive an actual `Error`. This will account for that.
 *
 * @example
 *
 * ```typescript
 * try {
 *   // ...
 * } catch (err) {
 *   const errorDetails = extractErrorDetails(err)
 *   console.error(errorDetails)
 * }
 * ```
 **/
export function getErrorMessage(err: unknown): string {
  // Check if the err is an instance of Error
  if (err instanceof Error) {
    // 1. Try to get the stack trace
    if (err.stack) {
      return err.stack
    }
    // 2. If stack trace is not available, fallback to message
    if (err.message) {
      return err.message
    }
  }

  // 3. If neither stack nor message is available, stringify the error object
  try {
    return JSON.stringify(err)
  } catch (jsonError) {
    // Handle the unlikely case where the error object itself causes a JSON serialization error.
    return String(err)
  }
}
