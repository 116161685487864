import { formatSize } from "@/lib/format.ts"
import { trpc } from "@/lib/trpc.ts"
import { cn } from "@/lib/tw-utils.ts"
import { ColumnDef } from "@tanstack/react-table"
import { ChevronDown, ChevronRight, CircleX, Folder as FolderIcon, PlusCircle } from "lucide-react"
import React from "react"
import { DataTable } from "./datatable.tsx"
import { Button } from "./ui/button.tsx"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "./ui/drawer.tsx"

export type GdriveFolderSelection = {
  name: string
  gdriveFolderId: string
  gdriveParentFolderId: string | null
}

export type GdriveFolderSelectorV2Props = {
  onChange: (value: GdriveFolderSelection[]) => void
  value?: GdriveFolderSelection[]
}

export function GdriveFolderSelector(props: GdriveFolderSelectorV2Props) {
  const [rootFolders, setRootFolders] = React.useState<GdriveFolderSelection[]>([])

  const [gdriveFolders, setGdriveFolders] = React.useState<GdriveFolderSelection[]>(
    props.value ?? [],
  )

  const [fileStatistics, setFileStatistics] = React.useState({ fileCount: 0, totalSize: 0 })

  const mounted = React.useRef(true)

  React.useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  React.useEffect(() => {
    setFileStatistics({ fileCount: 0, totalSize: 0 })
    const run = async () => {
      if (gdriveFolders.length === 0) return
      const stats = await trpc.knowledge.getStatisticsForGdriveFolders.query({
        folderIds: gdriveFolders.map((f) => f.gdriveFolderId),
      })
      if (!mounted.current) return
      setFileStatistics(stats)
    }
    run()
  }, [gdriveFolders])

  React.useEffect(() => {
    const load = async () => {
      const contents = await trpc.knowledge.listGdriveFolderContents.query({})
      const folders = contents.filter((c) => c.type === "folder")
      if (!mounted.current) return
      setRootFolders(folders)
    }
    load()
  }, [])

  React.useEffect(() => {
    props.onChange(gdriveFolders)
  }, [gdriveFolders])

  const [open, setOpen] = React.useState(false)

  async function onFolderSelected(folder: GdriveFolderSelection) {
    if (!gdriveFolders.some((f) => f.gdriveFolderId === folder.gdriveFolderId)) {
      setGdriveFolders((prev) => [...prev, folder])
    }
    setOpen(false)
  }

  async function removeFolder(folder: GdriveFolderSelection) {
    setGdriveFolders((prev) => prev.filter((f) => f.gdriveFolderId !== folder.gdriveFolderId))
  }

  const columns: ColumnDef<GdriveFolderSelection>[] = [
    {
      accessorKey: "name",
      header: "Folder Name",
    },
    {
      id: "action",
      header: "",
      cell: ({ row }) => {
        const record = row.original
        return (
          <div className="flex items-center justify-end gap-3 whitespace-nowrap">
            <Button variant="ghost" size="icon" onClick={() => removeFolder(record)}>
              <CircleX />
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <div className="mb-2 flex justify-between">
        <div
          className={cn(
            "text-muted-foreground flex items-center text-sm",
            fileStatistics.fileCount === 0 && "opacity-0",
          )}
        >
          {fileStatistics.fileCount} files, {formatSize(fileStatistics.totalSize)}
        </div>
        <Drawer open={open} onOpenChange={setOpen}>
          <DrawerTrigger>
            <Button type="button" size="sm">
              Add Folder
            </Button>
          </DrawerTrigger>
          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>Select Folder</DrawerTitle>
              <DrawerDescription>
                Browse and select a folder by clicking the "+" icon to the right of the respective
                folder. You can expand a folder by clicking the folder name.
              </DrawerDescription>
            </DrawerHeader>
            <div className="h-[60vh] overflow-auto">
              <FolderList folders={rootFolders} depth={0} onSelected={onFolderSelected} />
            </div>
            <DrawerFooter>
              <DrawerClose>
                <Button variant="outline">Cancel</Button>
              </DrawerClose>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
      <DataTable
        columns={columns}
        data={gdriveFolders}
        emptyMessage="No folders/files selected yet"
      />
    </>
  )
}

function FolderList(props: {
  folders: GdriveFolderSelection[]
  depth: number
  onSelected: (folder: GdriveFolderSelection) => void
}) {
  return (
    <>
      {props.folders.map((f) => (
        <Folder
          key={f.gdriveFolderId}
          folder={f}
          depth={props.depth}
          onSelected={props.onSelected}
        />
      ))}
      {props.folders.length === 0 && <div className="ml-6">Empty</div>}
    </>
  )
}

function Folder(props: {
  folder: GdriveFolderSelection
  depth: number
  onSelected: (folder: GdriveFolderSelection) => void
}) {
  const mounted = React.useRef(true)

  const [expanded, setExpanded] = React.useState(false)
  const [children, setChildren] = React.useState<GdriveFolderSelection[]>([])
  const [fetched, setFetched] = React.useState(false)

  React.useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  React.useEffect(() => {
    if (!expanded) return
    const load = async () => {
      try {
        const contents = await trpc.knowledge.listGdriveFolderContents.query({
          folderId: props.folder.gdriveFolderId,
        })
        if (!mounted.current) return
        setChildren(
          contents.filter((c) => c.type === "folder").map((f) => ({ ...f, selected: false })),
        )
      } finally {
        setFetched(true)
      }
    }
    load()
  }, [expanded])

  return (
    <>
      <div className={cn("hover:bg-foreground/15 flex h-12 items-center gap-2 p-2")}>
        <div
          className={cn(
            "flex flex-grow items-center",
            props.depth === 1 && "pl-[18px]",
            props.depth === 2 && "pl-[36px]",
            props.depth === 3 && "pl-[54px]",
            props.depth === 4 && "pl-[72px]",
            props.depth === 5 && "pl-[90px]",
            props.depth === 6 && "pl-[108px]",
          )}
        >
          <div
            className="flex cursor-pointer items-center gap-2"
            role="button"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? <ChevronDown /> : <ChevronRight />}
            <FolderIcon />
            {props.folder.name}
          </div>
        </div>
        <Button onClick={() => props.onSelected(props.folder)} size="icon" variant="ghost">
          <PlusCircle />
        </Button>
      </div>
      {expanded && children.length > 0 && (
        <FolderList folders={children} depth={props.depth + 1} onSelected={props.onSelected} />
      )}
      {expanded && fetched && children.length === 0 && (
        <div
          className={cn(
            "ml-[40px] flex h-12 flex-grow items-center gap-2",
            props.depth === 1 && "pl-[18px]",
            props.depth === 2 && "pl-[36px]",
            props.depth === 3 && "pl-[54px]",
            props.depth === 4 && "pl-[72px]",
            props.depth === 5 && "pl-[90px]",
            props.depth === 6 && "pl-[108px]",
          )}
        >
          <em>(no more folders)</em>
        </div>
      )}
    </>
  )
}
