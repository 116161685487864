import { H1, P } from "@/components/ui/typography.tsx"

export function Error403Page() {
  return (
    <>
      <H1>ERROR 403</H1>
      <P className="text-balance">
        You do not have sufficient authorization to access the resource you requested.
      </P>
    </>
  )
}
