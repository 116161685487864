import { type ControllerRenderProps } from "react-hook-form"

import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx"
import { SelectInput, type SelectInputProps } from "@/components/ui/select-input.tsx"

export type SelectFieldProps = {
  field: ControllerRenderProps<any, any>
  description?: string
  label?: string
} & SelectInputProps

export function SelectField(props: SelectFieldProps) {
  const { field, description, label, onValueChange, ...selectProps } = props

  return (
    <FormItem>
      {props.label && <FormLabel htmlFor={props.name}>{props.label}</FormLabel>}
      {description && <FormDescription>{description}</FormDescription>}
      <FormControl>
        <SelectInput
          {...selectProps}
          onValueChange={(value) => {
            field.onChange(value)
            onValueChange?.(value)
          }}
          defaultValue={field.value}
        />
      </FormControl>
      <FormMessage />
    </FormItem>
  )
}
