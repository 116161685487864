import { z, ZodObject } from "zod"

export function parseQueryParams<T extends ZodObject<any, any>>(
  url: string,
  schema: T,
): z.infer<T> | null {
  const urlObject = new URL(url)
  const queryParams: Record<string, string> = {}

  urlObject.searchParams.forEach((value, key) => {
    queryParams[key] = value
  })

  // If there are no query string parameters
  if (Object.keys(queryParams).length === 0) {
    return null
  }

  return schema.parse(queryParams)
}
