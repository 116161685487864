import { Button } from "@/components/ui/button.tsx"
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form.tsx"
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp.tsx"
import { useAuthUrls } from "@/context/auth.tsx"
import { zodResolver } from "@hookform/resolvers/zod"
import { REGEXP_ONLY_DIGITS } from "input-otp"
import React from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"

const emailFormSchema = z.object({
  code: z.string().regex(/^\d{6}$/, {
    message: "Your code should be a 6 digit number.",
  }),
})

export function AuthCodePage() {
  const loginUrls = useAuthUrls()

  const params = React.useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    return { code: params.get("code") || "", error: params.get("error") || "" }
  }, [])

  const codeForm = useForm<z.infer<typeof emailFormSchema>>({
    resolver: zodResolver(emailFormSchema),
    defaultValues: {
      code: params.code,
    },
  })

  function onCodeSubmit(values: z.infer<typeof emailFormSchema>) {
    window.location.href = loginUrls.code({ code: values.code })
  }

  return (
    <>
      <div className="grid gap-2 text-center"></div>
      <div className="grid gap-6">
        <h1 className="gap-4 text-2xl font-bold">NetLife AI</h1>
        <p className="text-muted-foreground text-balance">
          Please enter the code we sent to your email to complete your login.{" "}
          <span className="text-foreground">*</span>
        </p>
      </div>
      <Form {...codeForm}>
        <form onSubmit={codeForm.handleSubmit(onCodeSubmit)}>
          <div className="grid gap-8">
            <FormField
              control={codeForm.control}
              disabled={codeForm.formState.isLoading || codeForm.formState.isSubmitting}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <div className="flex justify-center">
                    <FormControl>
                      <InputOTP
                        autoFocus={!params.code}
                        pattern={REGEXP_ONLY_DIGITS}
                        maxLength={6}
                        {...field}
                      >
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="gap-2"
              size="sm"
              disabled={codeForm.formState.isLoading || codeForm.formState.isSubmitting}
            >
              Verify Code
            </Button>
            <div className="text-muted-foreground text-sm">
              Please note that it can take up to a minute for the email to arrive. We recommend
              checking your spam folder too.
            </div>
          </div>
        </form>
      </Form>
      {params.error && (
        <div className="text-sm text-red-500">
          {params.error === "invalid"
            ? "The code you entered is invalid. Please try again."
            : "An error occurred."}
        </div>
      )}
    </>
  )
}
