import { DataTable } from "@/components/datatable.tsx"
import { KnowledgeForm } from "@/components/knowledge-form.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { formatSize } from "@/lib/format.ts"
import { trpc } from "@/lib/trpc.ts"
import { ColumnDef } from "@tanstack/react-table"
import React from "react"
import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"

export async function knowledgeEditPageLoader({ params }: LoaderFunctionArgs) {
  const account = authenticateGuard()
  invariant(account.role === "admin", "Account is required")
  invariant(params.knowledgeId, "knowledgeId is required")
  const { knowledge, sources, fileStatistics } = await trpc.knowledge.get.query({
    knowledgeId: params.knowledgeId,
  })
  invariant(knowledge, "Knowledge not found")
  const [chatBots, badFiles] = await Promise.all([
    trpc.chatbots.listByKnowledge.query({
      knowledgeId: params.knowledgeId,
    }),
    trpc.knowledge.listBadFiles.query({ knowledgeId: params.knowledgeId }),
  ])
  console.log(chatBots)
  return { knowledge, sources, fileStatistics, chatBots, badFiles }
}

type LoaderData = Awaited<ReturnType<typeof knowledgeEditPageLoader>>

type ChatBotRecord = LoaderData["chatBots"][number]
type BadFileRecord = LoaderData["badFiles"][number] & { viewError: () => void }

const badFilesTableColumns: ColumnDef<BadFileRecord>[] = [
  {
    accessorKey: "gdriveFileName",
    header: "Name",
  },
  {
    accessorKey: "gdriveMimeType",
    header: "Mime Type",
  },
  {
    header: "Size",
    cell: ({ row }) => {
      const record = row.original
      return formatSize(record.gdriveFileSize)
    },
  },
  {
    header: "Attempts",
    cell: ({ row }) => {
      const record = row.original
      return record.attempts ?? 1
    },
  },
  {
    header: "Invalid",
    cell: ({ row }) => {
      const record = row.original
      return record.hardFailed ? "Yes" : "No"
    },
  },
  {
    header: "Actions",
    cell: ({ row }) => {
      const record = row.original
      return (
        <div className="flex flex-row gap-3">
          <Button type="button" variant="outline" size="sm" onClick={record.viewError}>
            View Error
          </Button>
          {record.gdriveFileWebViewLink && (
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                window.open(record.gdriveFileWebViewLink!, "_blank")
              }}
            >
              View in Gdrive
            </Button>
          )}
        </div>
      )
    },
  },
]

const chatBotTableColumns: ColumnDef<ChatBotRecord>[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "timeUpdated",
    header: "Updated",
  },
]

export function KnowledgeEditPage() {
  const data = useLoaderData() as LoaderData

  const [openErrorDrawer, setOpenErrorDrawer] = React.useState(false)
  const [errorReason, setErrorReason] = React.useState<string>("")

  const navigate = useNavigate()

  const badFilesData = React.useMemo(
    () =>
      data.badFiles.map((r) => ({
        ...r,
        viewError: () => {
          setErrorReason(r.reason)
          setOpenErrorDrawer(true)
        },
      })),
    [data.badFiles],
  )

  return (
    <>
      <H1 className="mb-4">Update Knowledge</H1>
      <Lead className="mb-8 text-balance">Update the configuration this knowledge base below.</Lead>

      <KnowledgeForm
        data={data}
        onSubmit={async (values) => {
          await trpc.knowledge.update.mutate({
            knowledgeId: data.knowledge.id,
            name: values.name,
            description: values.description,
            gdriveFolderIds: values.gdriveFolders.map((f) => f.gdriveFolderId),
          })
          navigate("/admin/knowledge")
        }}
        actions={(formState) => (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Chat Bots</CardTitle>
                <CardDescription>
                  The following ChatBots are utilizing this Knowledge.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <DataTable<ChatBotRecord, unknown>
                  columns={chatBotTableColumns}
                  data={data.chatBots}
                  onRowClicked={(row: ChatBotRecord) => {
                    navigate(`/admin/chatbots/${row.slug ?? row.id}`)
                  }}
                />
              </CardContent>
            </Card>

            <Card className="mb-8">
              <CardHeader>
                <CardTitle>Training Progress</CardTitle>
                <CardDescription>
                  Below are the statistics on the training progress for the source files that have
                  been added to this Knowledge. The training progress will indicate how much of the
                  source file data is available to the ChatBots leveraging this Knowledge.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid grid-cols-2 gap-6">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-sm font-medium">Training Progress</CardTitle>
                    <CardDescription>
                      The total progress of processing all of the source files.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">
                      {data.fileStatistics.sourceFileCount === 0
                        ? 100
                        : Math.floor(
                            ((data.fileStatistics.syncFailedFileCount +
                              data.fileStatistics.syncedFileCount) /
                              data.fileStatistics.sourceFileCount) *
                              100,
                          )}{" "}
                      %
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle className="text-sm font-medium">Failed Count</CardTitle>
                    <CardDescription>Number of source files where training failed.</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">
                      {data.fileStatistics.syncFailedFileCount}
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle className="text-sm font-medium">Trained Count</CardTitle>
                    <CardDescription>Number of files where training completed.</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">{data.fileStatistics.syncedFileCount}</div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle className="text-sm font-medium">Trained Size</CardTitle>
                    <CardDescription>Total size of files where training completed.</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">
                      {formatSize(data.fileStatistics.syncedFileSize)}
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle className="text-sm font-medium">Source Count</CardTitle>
                    <CardDescription>Number of source files for training.</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">{data.fileStatistics.sourceFileCount}</div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle className="text-sm font-medium">Source Size</CardTitle>
                    <CardDescription>Total size of source files for training.</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">
                      {formatSize(data.fileStatistics.sourceFileSize)}
                    </div>
                  </CardContent>
                </Card>
              </CardContent>
            </Card>

            <div className="flex flex-grow justify-between">
              <div className="flex gap-3">
                <Button
                  type="submit"
                  variant="default"
                  disabled={formState.isSubmitting || formState.isLoading}
                >
                  Update Knowledge
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={formState.isSubmitting || formState.isLoading}
                  onClick={() => {
                    navigate("/admin/knowledge")
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="flex gap-3">
                <Button
                  type="button"
                  variant="secondary"
                  disabled={formState.isSubmitting || formState.isLoading}
                  onClick={async () => {
                    await trpc.knowledge.resync.mutate({ knowledgeId: data.knowledge.id })
                    navigate("/admin/knowledge")
                  }}
                >
                  Resync
                </Button>

                <Dialog>
                  <DialogTrigger asChild={true}>
                    <Button
                      type="button"
                      variant="destructive"
                      disabled={formState.isSubmitting || formState.isLoading}
                    >
                      Delete
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogTitle>Confirmation Needed</DialogTitle>
                    <DialogDescription>
                      You have requested that this Knowledge be removed from our system. This action
                      cannot be reversed. Any ChatBot that was using this Knowledge will no longer
                      be able to leverage the data represented by the Knowledge. Please confirm the
                      deletion below, or cancel if you wish to keep the Knowledge.
                    </DialogDescription>
                    <DialogFooter>
                      <Button
                        variant="destructive"
                        onClick={async () => {
                          await trpc.knowledge.remove.mutate({ knowledgeId: data.knowledge.id })
                          navigate("/admin/knowledge")
                        }}
                      >
                        Confirm Delete
                      </Button>
                      <DialogClose asChild={true}>
                        <Button>Cancel</Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </div>

            <Card className="mt-8">
              <CardHeader>
                <CardTitle>Failed File Training</CardTitle>
                <CardDescription>
                  Training could not be completed on the following files. We will perform a few
                  attempts before we mark the file as invalid.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <DataTable<BadFileRecord, unknown>
                  columns={badFilesTableColumns}
                  data={badFilesData}
                />
              </CardContent>
            </Card>

            <Card className="mt-8">
              <CardHeader>
                <CardTitle>Debugging</CardTitle>
                <CardDescription>
                  This section provides debugging information, which can be used by engineers to
                  help support issues with ChatBots.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid gap-6">
                <Button asChild={true} variant="secondary">
                  <a
                    href={`https://platform.openai.com/storage/vector_stores/${data.knowledge.openaiVectorId}`}
                    target="_blank"
                  >
                    View OpenAI Vector
                  </a>
                </Button>
              </CardContent>
            </Card>
          </>
        )}
      />
      <Drawer open={openErrorDrawer} onOpenChange={setOpenErrorDrawer}>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Failed Fail Details</DrawerTitle>
            <DrawerDescription>
              Below is the recorded error log from the server for our most recent attempt at
              training agains the file.
            </DrawerDescription>
          </DrawerHeader>
          <div className="h-[60vh] overflow-auto p-5 font-mono text-sm">{errorReason}</div>
          <DrawerFooter>
            <DrawerClose>
              <Button variant="outline">Close</Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
