import { RouterOutput, trpc } from "@/lib/trpc.ts"
import { ColumnDef } from "@tanstack/react-table"
import { CircleX, PlusCircle } from "lucide-react"
import React from "react"
import { DataTable } from "./datatable.tsx"
import { Button } from "./ui/button.tsx"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "./ui/drawer.tsx"

export type KnowledgeSelectorProps = {
  onChange: (value: Knowledge[]) => void
  value?: Knowledge[]
}

export type Knowledge = Pick<
  RouterOutput["knowledge"]["list"][number],
  "id" | "name" | "description"
>

export function KnowledgeSelector(props: KnowledgeSelectorProps) {
  const [knowledge, setKnowledge] = React.useState<Knowledge[]>([])

  const [selected, setSelected] = React.useState<Knowledge[]>(props.value ?? [])

  const mounted = React.useRef(true)

  React.useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  React.useEffect(() => {
    const load = async () => {
      const knowledge = await trpc.knowledge.list.query()
      if (!mounted.current) return
      setKnowledge(knowledge)
    }
    load()
  }, [])

  React.useEffect(() => {
    props.onChange(selected)
  }, [selected])

  const [open, setOpen] = React.useState(false)

  async function onKnowledgeSelected(knowledge: Knowledge) {
    if (!selected.some((f) => f.id === knowledge.id)) {
      setSelected((prev) => [...prev, knowledge])
    }
    setOpen(false)
  }

  async function removeKnowledge(knowledge: Knowledge) {
    setSelected((prev) => prev.filter((f) => f.id !== knowledge.id))
  }

  const selectedTableColumns: ColumnDef<Knowledge>[] = [
    {
      accessorKey: "name",
      header: "Knowledge Name",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      id: "action",
      header: "",
      cell: ({ row }) => {
        const record = row.original
        return (
          <div className="flex items-center justify-end gap-3 whitespace-nowrap">
            <Button variant="ghost" size="icon" onClick={() => removeKnowledge(record)}>
              <CircleX />
            </Button>
          </div>
        )
      },
    },
  ]

  const selectTableColumns: ColumnDef<Knowledge>[] = [
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      id: "action",
      header: "",
      cell: ({ row }) => {
        const record = row.original
        return (
          <div className="flex items-center justify-end gap-3 whitespace-nowrap">
            <Button variant="ghost" size="icon" onClick={() => onKnowledgeSelected(record)}>
              <PlusCircle />
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      {selected.length > 0 ? (
        <DataTable
          columns={selectedTableColumns}
          data={selected}
          emptyMessage="No Knowledge selected"
        />
      ) : (
        <div />
      )}
      <div className="mb-2 flex">
        <Drawer open={open} onOpenChange={setOpen}>
          <DrawerTrigger>
            <Button type="button" size="sm">
              Add Knowledge
            </Button>
          </DrawerTrigger>
          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>Select Knowledge</DrawerTitle>
              <DrawerDescription>
                Select the Knowledge that you would like to include.
              </DrawerDescription>
            </DrawerHeader>
            <div className="h-[60vh] overflow-auto">
              <DataTable
                columns={selectTableColumns}
                data={knowledge}
                emptyMessage="No Knowledge available"
              />
            </div>
            <DrawerFooter>
              <DrawerClose>
                <Button variant="outline">Cancel</Button>
              </DrawerClose>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
    </>
  )
}
