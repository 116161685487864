import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { trpc } from "@/lib/trpc.ts"
import { Link, useLoaderData } from "react-router-dom"

export async function dashboardLoader() {
  const chatBots = await trpc.chatbots.list.query()
  return { chatBots }
}

type LoaderData = Awaited<ReturnType<typeof dashboardLoader>>

export function DashboardPage() {
  const data = useLoaderData() as LoaderData

  return (
    <Main>
      <H1 className="mb-4">NetLife AI</H1>
      <Lead className="mb-8 text-balance">
        Welcome to our ChatBot hub - your gateway to a smarter, more efficient way of engaging with
        our innovative circular economy solutions. Our suite of AI-powered chatbots are designed to
        enhance your experience by providing instant access to a range of training materials,
        insights, and services. Below are the ChatBots that are currently available to you.
      </Lead>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 lg:gap-8 xl:grid-cols-4">
        {data.chatBots.map((chatBot) => (
          <Card key={chatBot.id} className="flex flex-col">
            <CardHeader>
              <CardTitle>{chatBot.name}</CardTitle>
            </CardHeader>
            <CardContent className="flex-grow">
              <p>{chatBot.description}</p>
            </CardContent>
            <CardFooter>
              <Button asChild={true} className="w-full">
                <Link to={`/chat/${chatBot.slug ?? chatBot.id}`}>Chat</Link>
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>
    </Main>
  )
}
