import { Button } from "@/components/ui/button.tsx"
import { trpc } from "@/lib/trpc.ts"
import { cn } from "@/lib/tw-utils.ts"
import React from "react"
import { useLocation } from "react-router-dom"
import { useAuth } from "./auth.tsx"

export function useLogAnalyticsEvent() {
  const { logEvent } = React.useContext(AnalyticsContext)
  return logEvent
}

interface DataLayerEvent {
  event: string
  [key: string]: any
}

type AnalyticsContextType = {
  logEvent: (eventName: string, eventData: Record<string, any>) => void
}

const AnalyticsContext = React.createContext<AnalyticsContextType>({
  logEvent: () => {
    throw new Error("AnalyticsContext not initialized")
  },
})

export type AnalyticsProviderProps = {
  children: React.ReactNode
}

export function AnalyticsProvider({ children }: AnalyticsProviderProps) {
  const { session } = useAuth()

  const location = useLocation()

  const [consent, setConsent] = React.useState<"accepted" | "declined" | null>(() => {
    // check if the user has already given consent (stored in their account)
    if (session?.account.cookieConsent) {
      return session.account.cookieConsent
    }
    // the user might have given consent before logging in
    const localStorageConsent = localStorage.getItem("cookieConsent")
    if (localStorageConsent === "accepted" || localStorageConsent === "declined") {
      return localStorageConsent
    }
    return null
  })

  // If Google Analytics hasn't been loaded we will stub the API out
  React.useLayoutEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.gtag =
      window.gtag ||
      function () {
        // @ts-ignore
        window.dataLayer.push(arguments)
      }
  }, [])

  // Ensure analytics are enabled/disabled based on the consent
  React.useEffect(() => {
    window.gtag("consent", "update", {
      analytics_storage: consent === "accepted" ? "granted" : "denied",
    })
  }, [consent])

  const logEvent = React.useCallback(
    (eventName: string, eventData: Record<string, any>) => {
      const data: DataLayerEvent = {
        event: eventName,
        ...eventData,
      }
      if (session) {
        data.userId = session.account.id
      }
      window.dataLayer.push(data)
    },
    [session],
  )

  // Log every route change
  React.useEffect(() => {
    window.gtag?.("config", "G-TH0E2E0341", {
      page_path: location.pathname,
    })
  }, [location.pathname])

  // Handle a user login
  React.useEffect(() => {
    if (session) {
      window.dataLayer?.push({
        event: "user_login",
        userId: session.account.id,
      })
    }
  }, [session])

  // Ensure a user's consent is stored in their account
  React.useEffect(() => {
    if (session && consent != null && session.account.cookieConsent !== consent) {
      trpc.users.setCookieConsent.mutate({
        consent: consent,
      })
    }
  }, [session, consent])

  const saveConsentAnswer = React.useCallback(
    async (consent: "accepted" | "declined") => {
      setConsent(consent)
      localStorage.setItem("cookieConsent", consent)
      if (session) {
        await trpc.users.setCookieConsent.mutate({
          consent: consent,
        })
      }
    },
    [session],
  )
  const handleAccept = React.useCallback(() => saveConsentAnswer("accepted"), [saveConsentAnswer])
  const handleDecline = React.useCallback(() => saveConsentAnswer("declined"), [saveConsentAnswer])

  return (
    <AnalyticsContext.Provider value={{ logEvent }}>
      {children}
      {consent == null ? <CookieBanner onAccept={handleAccept} onDecline={handleDecline} /> : null}
    </AnalyticsContext.Provider>
  )
}

type CookieBannerProps = {
  onAccept: () => void
  onDecline: () => void
}

function CookieBanner(props: CookieBannerProps) {
  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 right-0 z-50 border-t border-gray-200 bg-white p-4",
        "flex flex-col items-center justify-between space-y-2 md:flex-row md:space-y-0",
      )}
    >
      <p className="text-sm text-gray-700">
        We use cookies to ensure you get the best experience on our website.{" "}
        <a href="/privacy-policy" target="_blank" className="underline">
          Learn more
        </a>
        .
      </p>
      <div className="flex space-x-4">
        <Button variant="default" size="sm" onClick={props.onAccept}>
          Accept
        </Button>
        <Button variant="secondary" size="sm" onClick={props.onDecline}>
          Decline
        </Button>
      </div>
    </div>
  )
}
