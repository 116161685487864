import { Chatbot, ChatBotMessage, useChatBotEndpoint } from "@/components/chatbot.tsx"
import { useLogAnalyticsEvent } from "@/context/analytics.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import React from "react"
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom"
import invariant from "tiny-invariant"

export async function chatBotPageLoader({ params }: LoaderFunctionArgs) {
  const account = authenticateGuard()
  invariant(params.chatBotId, "chatBotId is required")

  // get by slug first, if not found, get by id
  let chatBot = await trpc.chatbots.getBySlug.query({ slug: params.chatBotId })
  if (!chatBot) {
    chatBot = await trpc.chatbots.get.query({ chatBotId: params.chatBotId })
  }
  invariant(chatBot, "Chat Bot not found")

  const threadId = chatBot.memory
    ? await trpc.chatbots.getThread.query({ chatBotId: chatBot.id })
    : undefined

  return { chatBot, userEmail: account.email, threadId }
}

type LoaderData = Awaited<ReturnType<typeof chatBotPageLoader>>

function createInitialMessages(data: LoaderData): ChatBotMessage[] {
  return data.chatBot.memory && data.threadId
    ? [
        {
          role: "assistant",
          parts: [
            {
              type: "text",
              content: data.chatBot.returnMessage ?? "Welcome back, how can I help you?",
            },
          ],
          batch: 0,
        },
      ]
    : [
        {
          role: "assistant",
          parts: [
            {
              type: "text",
              content: data.chatBot.openingMessage ?? "Welcome, how can I help you today?",
            },
          ],
          batch: 0,
        },
      ]
}

export function ChatBotPage() {
  const data = useLoaderData() as LoaderData

  const logAnalyticsEvent = useLogAnalyticsEvent()

  const [messages, setMessages] = React.useState<ChatBotMessage[]>(() => {
    return createInitialMessages(data)
  })

  React.useEffect(() => {
    if (data.chatBot) {
      setMessages(createInitialMessages(data))
    }
  }, [data.chatBot.id])

  const chatbotEP = useChatBotEndpoint({
    endpointUrl: import.meta.env.VITE_CHATBOTS_STREAM_CHAT_IN_THREAD_URL,
    endpointBody: (userMessage: string, threadId: string) => ({
      threadId,
      prompt: userMessage,
      chatBotId: data.chatBot.id,
    }),
    threadId: data.threadId,
    startThread: async () => {
      const thread = await trpc.chatbots.startThread.mutate({
        chatBotId: data.chatBot.id,
      })
      return thread.threadId
    },
    setMessages,
    onUserMessageSent: (_userMessage, threadId) => {
      logAnalyticsEvent("chatbot_message_sent", {
        chatBotId: data.chatBot.id,
        chatBotName: data.chatBot.name,
        threadId,
      })
      // TODO: Remove umami
      if (window.umami) {
        window.umami.track("ChatBot Message Sent", {
          chatBotId: data.chatBot.id,
          chatBotName: data.chatBot.name,
          threadId,
          userEmail: data.userEmail,
        })
      }
    },
  })

  return (
    <Chatbot
      messages={messages}
      streamingResponse={chatbotEP.streamingResponse}
      waitingForResponse={chatbotEP.waitingForResponse}
      streamChunkCount={chatbotEP.streamChunkCount}
      onSendUserMessage={chatbotEP.onSendUserMessage}
    />
  )
}
