import { Section } from "@/components/ui/page-layout.tsx"
import { Seperator } from "@/components/ui/seperator.tsx"
import { H1, H2, InlineCode, Lead, P, Ul } from "@/components/ui/typography.tsx"

export function PrivacyPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <H1>Privacy Policy</H1>
      <Lead>Effective Date: 2024/10/15</Lead>

      <Seperator />

      <Section>
        <H2>1. Introduction</H2>
        <P>
          "NetLife AI" is operated by NetLife LLC (<InlineCode>we</InlineCode>,{" "}
          <InlineCode>our</InlineCode>, or <InlineCode>us</InlineCode>). This Privacy Policy
          explains how we collect, use, and protect your personal information when you use our
          services.
        </P>
      </Section>

      <Section>
        <H2>2. Information We Collect</H2>
        <Ul className="list-inside list-disc space-y-2">
          <li>
            <span className="font-bold">Email Address:</span> We collect your email address when you
            log in to our platform. No other personally identifiable information is stored.
          </li>
          <li>
            <span className="font-bold">Usage Data:</span> We use Google Analytics to track your
            journey through our application to improve our services.
          </li>
          <li>
            <span className="font-bold">Chat History:</span> We save your chat history for your
            convenience. This data is accessible only to you and not to NetLife staff.
          </li>
        </Ul>
      </Section>

      <Section>
        <H2>3. How We Use Your Information</H2>
        <Ul className="list-inside list-disc space-y-1">
          <li>To provide and maintain our services.</li>
          <li>To personalize your experience.</li>
          <li>To improve our platform based on usage analytics.</li>
          <li>To communicate with you regarding updates or support.</li>
        </Ul>
      </Section>

      <Section>
        <H2>4. Data Control and Removal</H2>
        <P>
          You have the ability to remove or disable your chat history at any time within the
          platform.
        </P>
        <P>
          You may request the deletion of any data related to you by contacting{" "}
          <InlineCode>support@netlife.ai</InlineCode>.
        </P>
      </Section>

      <Section>
        <H2>5. Data Sharing and Disclosure</H2>
        <P>
          We do not share your personal information with third parties except as necessary to
          provide our services or comply with legal obligations.
        </P>
      </Section>

      <Section>
        <H2>6. Security</H2>
        <P>We implement industry-standard security measures to protect your information.</P>
      </Section>

      <Section>
        <H2>7. Changes to This Privacy Policy</H2>
        <P>
          We may update this Privacy Policy from time to time. We will notify you of any changes by
          updating the date at the top of this policy.
        </P>
      </Section>

      <Section>
        <H2>8. Contact Us</H2>
        <P>
          If you have any questions about this Privacy Policy, please contact us at{" "}
          <InlineCode>support@netlife.ai</InlineCode>.
        </P>
      </Section>
    </div>
  )
}
