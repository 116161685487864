import { trpc } from "@/lib/trpc.ts"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { InputField } from "./hook-form/input-field.tsx"
import { TextareaField } from "./hook-form/textarea-field.tsx"
import { Button } from "./ui/button.tsx"
import { Form, FormField } from "./ui/form.tsx"
import { H1, P } from "./ui/typography.tsx"

const formSchema = z.object({
  company: z.string().min(1, {
    message: "Please provide the name of your company.",
  }),
  role: z.string().min(1, {
    message: "Please provide your role.",
  }),
  reason: z.string(),
})

export function RequestAccessForm() {
  const [submitted, setSubmitted] = React.useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      company: "",
      role: "",
      reason: "",
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    await trpc.users.requestAccess.mutate(values)
    setSubmitted(true)
  }

  if (submitted) {
    return (
      <div className="mx-auto mt-4 grid w-full max-w-lg gap-8 px-8 md:mt-8">
        <div>
          <H1>Access Request Sent</H1>
          <P>
            Your request has been submitted and is under review by our team. We will endeavor to
            review your request as quickly as possible, and we thank you for your patience.
          </P>
          <P>You will receive an email notification once it has been approved.</P>
        </div>
      </div>
    )
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mx-auto mt-4 grid w-full max-w-lg gap-8 px-8 md:mt-8"
      >
        <div>
          <H1>Access Request</H1>
          <P>
            Apologies, your email address has currently not been authorised to access NetLife AI.
            You can request access by providing your details below.
          </P>
        </div>
        <div className="grid gap-4">
          <div className="grid gap-3">
            <FormField
              control={form.control}
              name="company"
              disabled={form.formState.isSubmitting || form.formState.isLoading}
              render={({ field }) => <InputField field={field} label="Company *" autoFocus />}
            />
          </div>
          <div className="grid gap-3">
            <FormField
              control={form.control}
              name="role"
              disabled={form.formState.isSubmitting || form.formState.isLoading}
              render={({ field }) => <InputField field={field} label="Role *" />}
            />
          </div>
          <div className="grid gap-3">
            <FormField
              control={form.control}
              name="reason"
              disabled={form.formState.isSubmitting || form.formState.isLoading}
              render={({ field }) => <TextareaField field={field} label="Reason" />}
            />
          </div>
        </div>
        <div className="grid gap-3">
          <Button
            type="submit"
            className="w-full"
            disabled={form.formState.isSubmitting || form.formState.isLoading}
          >
            Send Request
          </Button>
        </div>
      </form>
    </Form>
  )
}
