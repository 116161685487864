import { AnalyticsProvider } from "@/context/analytics.tsx"
import { Outlet } from "react-router-dom"

export function AuthLayout() {
  return (
    <AnalyticsProvider>
      <div className="flex min-h-screen w-full items-center justify-center xl:grid xl:flex-none xl:grid-cols-2 xl:items-stretch xl:justify-start">
        <div className="flex items-center justify-center py-12">
          <div className="mx-auto grid w-[350px] gap-8 px-4">
            <Outlet />
          </div>
        </div>
        <div className="bg-foreground/5 hidden xl:block">
          <div className="flex h-full w-full items-center justify-center p-5">
            <img
              src="/images/logo-full.svg"
              alt="NetLife"
              className="inline-block w-full max-w-[250px]"
            />
          </div>
        </div>
      </div>
    </AnalyticsProvider>
  )
}
