import { P } from "@/components/ui/typography.tsx"
import { ArrowLeft, Mail } from "lucide-react"
import { Outlet, useNavigate } from "react-router-dom"

export function ErrorLayout() {
  const navigate = useNavigate()

  return (
    <div className="flex min-h-screen w-full items-center justify-center lg:grid lg:flex-none lg:grid-cols-2 lg:items-stretch lg:justify-start">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid max-w-[400px] gap-8">
          <Outlet />

          <a
            className="text-primary flex items-center gap-3"
            onClick={(e) => {
              e.preventDefault()
              navigate(-1)
            }}
            href="/"
          >
            <ArrowLeft /> Try go back
          </a>

          <div className="flex flex-col gap-6">
            <hr />
            <P>If you need help with this issue please contact our support team.</P>
            <P>
              <a
                className="text-primary flex items-center gap-3"
                href="mailto:NetLife AI Support<ai.support@netlife.co>"
              >
                <Mail /> NetLife AI Support{" "}
              </a>
            </P>
          </div>
        </div>
      </div>
    </div>
  )
}
