import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx"
import { cn } from "@/lib/tw-utils.ts"

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  emptyMessage?: string
  data: TData[]
  disableHeader?: boolean
  isRowClickable?: (row: TData) => boolean
  onRowClicked?: (row: TData) => void
}

export function DataTable<TData, TValue>(props: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Table>
      {!props.disableHeader && (
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
      )}
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => {
            const clickable = props.isRowClickable
              ? props.isRowClickable(row.original) && props.onRowClicked
              : props.onRowClicked != null
            return (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                onClick={() => {
                  if (clickable && props.onRowClicked) {
                    props.onRowClicked(row.original)
                  }
                }}
                className={cn(
                  clickable &&
                    "hover:bg-muted/50 hover:text-primary cursor-pointer transition-colors",
                )}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            )
          })
        ) : (
          <TableRow>
            <TableCell colSpan={props.columns.length} className="h-24 text-center">
              {props.emptyMessage ?? "No results."}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
