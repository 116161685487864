import { trpc } from "@/lib/trpc.ts"
import { cn } from "@/lib/tw-utils.ts"
import { FileText, Folder } from "lucide-react"
import React from "react"
import { Button } from "./ui/button.tsx"

type Folders = {
  name: string
  gdriveFolderId: string
  gdriveParentFolderId: string | null
  webViewLink: string | null
}[]

export function GdriveExplorer() {
  const mounted = React.useRef(true)
  const [tree, setTree] = React.useState<TreeNode | null>(null)
  const [folders, setFolders] = React.useState<Folders>([])

  React.useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  React.useEffect(() => {
    const run = async () => {
      const { files, folders } = await trpc.knowledge.getGdriveFoldersAndFiles.query()
      if (!mounted.current) return
      const tree = buildTree(files, folders)
      setTree(tree)
      setFolders(folders)
    }
    run()
  }, [])

  if (!tree) return null
  return <TreeRenderer tree={tree} folders={folders} />
}

type TreeNode = {
  id: string
  name: string
  mimeType?: string
  sizeBytes?: number
  gdriveCreatedTime?: string
  gdriveModifiedTime?: string
  openaiFileId?: string | null
  children: TreeNode[]
}

function Tree({
  node,
  setToggled,
  isToggled,
}: {
  node: TreeNode
  setToggled: (id: string) => void
  isToggled: (id: string) => boolean
}) {
  const isFile = "sizeBytes" in node
  const toggled = !isFile && isToggled(node.id)
  return (
    <div className="ml-4 flex flex-col gap-2 leading-6">
      <div
        className={cn(
          "flex items-center",
          !isFile && "text-foreground cursor-pointer",
          isFile && node.openaiFileId && "text-green-500",
          isFile && !node.openaiFileId && "text-orange-400",
        )}
        onClick={() => setToggled(node.id)}
      >
        {isFile ? <FileText /> : <Folder />}
        <span className="ml-2">{node.name}</span>
      </div>
      {toggled && node.children.length > 0 && (
        <div className="relative mb-2 flex flex-col gap-2 border-l border-gray-400 pb-2 pl-4">
          {node.children.map((child) => (
            <Tree key={child.id} node={child} setToggled={setToggled} isToggled={isToggled} />
          ))}
        </div>
      )}
    </div>
  )
}

function TreeRenderer({ tree, folders }: { tree: TreeNode; folders: Folders }) {
  const [expandedNodes, setExpandedNodes] = React.useState<Record<string, boolean>>({
    [tree.id]: true,
  })

  const setToggled = React.useCallback(
    (id: string) => {
      setExpandedNodes((previousExpandedNodes) => ({
        ...previousExpandedNodes,
        [id]: !previousExpandedNodes[id],
      }))
    },
    [setExpandedNodes],
  )

  const isToggled = React.useCallback(
    (id: string) => {
      return !!expandedNodes[id]
    },
    [expandedNodes],
  )

  return (
    <>
      <div className="mb-2 flex items-center gap-2">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => {
            const expandAllState = folders.reduce<Record<string, boolean>>(
              (acc, folder) => {
                acc[folder.gdriveFolderId] = true
                return acc
              },
              { [tree.id]: true },
            )
            setExpandedNodes(expandAllState)
          }}
        >
          Expand All
        </Button>
        |
        <Button variant="ghost" size="sm" onClick={() => setExpandedNodes({ [tree.id]: true })}>
          Collapse All
        </Button>
      </div>
      <Tree node={tree} setToggled={setToggled} isToggled={isToggled} />
    </>
  )
}

function buildTree(
  files: {
    gdriveFileId: string
    gdriveFolderId: string | null
    name: string
    sizeBytes: number
  }[],
  folders: {
    name: string
    gdriveFolderId: string
    gdriveParentFolderId: string | null
  }[],
): TreeNode {
  // Step 1: Create a map of folders by their IDs
  const folderMap = new Map<string, TreeNode>()
  folders.forEach((folder) => {
    folderMap.set(folder.gdriveFolderId, {
      id: folder.gdriveFolderId,
      name: folder.name,
      children: [],
    })
  })

  // Step 2: Add folders to their parent folder's children array
  let root: TreeNode = { id: "root", name: "NetLife AI Google Drive", children: [] } // Root folder container

  folders.forEach((folder) => {
    const treeNode = folderMap.get(folder.gdriveFolderId)!
    if (folder.gdriveParentFolderId) {
      const parentTreeNode = folderMap.get(folder.gdriveParentFolderId)
      parentTreeNode?.children.push(treeNode)
    } else {
      root.children.push(treeNode) // Add root-level folders to root
    }
  })

  // Step 3: Add files to their corresponding folders' children array
  files.forEach((file) => {
    const parentTreeNode = file.gdriveFolderId ? folderMap.get(file.gdriveFolderId) : root
    parentTreeNode?.children.push({
      id: file.gdriveFileId,
      name: file.name,
      sizeBytes: file.sizeBytes,
      children: [],
    })
  })

  return root
}
