import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  type SelectProps,
} from "@/components/ui/select.tsx"

export type SelectOption = { value: string; label: string }

export type SelectInputProps = {
  mode?: "popover" | "dropdown"
  options: SelectOption[]
  placeholder?: string
} & SelectProps

export function SelectInput(props: SelectInputProps) {
  const { options, placeholder, mode, ...selectProps } = props
  return (
    <Select {...selectProps}>
      <SelectTrigger>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent position={props.mode === "popover" ? "popper" : "item-aligned"}>
        <SelectGroup>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
