import { Chatbot, ChatBotMessage, useChatBotEndpoint } from "@/components/chatbot.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import React from "react"

export function prototypePageLoader() {
  authenticateGuard(["admin"])
  return null
}

export function PrototypePage() {
  const [messages, setMessages] = React.useState<ChatBotMessage[]>([
    {
      role: "assistant",
      parts: [{ type: "text", content: "How can I help you today?" }],
      batch: 0,
    },
  ])

  const chatbotEP = useChatBotEndpoint({
    endpointUrl: import.meta.env.VITE_SUPERBOT_URL,
    endpointBody: (userMessage: string, threadId: string) => ({
      threadId,
      prompt: userMessage,
    }),
    startThread: async () => {
      const threadId = await trpc.superbot.startThread.mutate()
      return threadId
    },
    setMessages,
  })

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="h-24 p-4">
        <div className="m-auto max-w-2xl text-balance text-center text-sm italic">
          This is a work in progress prototype of our "Chat with DataLake" capability. It will be
          riddled with bugs and bad experiences right now, as we are primarily focusing on unlocking
          and evolving the underlying architectural capabilities. Please{" "}
          <a
            href="https://gsmsystems.atlassian.net/wiki/spaces/NSE/pages/2107703300/Feedback"
            target="_blank"
            className="text-primary"
          >
            provide feedback here
          </a>
          .
        </div>
      </div>
      <div className="flex-grow overflow-auto">
        <Chatbot
          messages={messages}
          streamingResponse={chatbotEP.streamingResponse}
          waitingForResponse={chatbotEP.waitingForResponse}
          streamChunkCount={chatbotEP.streamChunkCount}
          onSendUserMessage={chatbotEP.onSendUserMessage}
        />
      </div>
    </div>
  )
}
