export type LogLevel = "none" | "error" | "warn" | "info" | "debug"

type LogData = object | string | number | boolean

type LogDataFunction = () => LogData

function mapLogLevel(level: LogLevel): number {
  switch (level) {
    case "none":
      return 0
    case "error":
      return 1
    case "warn":
      return 2
    case "info":
      return 3
    case "debug":
      return 4
    default:
      throw new Error(`Unknown log level: ${level}`)
  }
}

export class Logger {
  private logLevel: LogLevel
  private level: number
  private prefix: string
  private console: Console

  constructor(logLevel: LogLevel, prefix: string = "") {
    this.logLevel = logLevel
    this.level = mapLogLevel(logLevel)
    this.prefix = prefix
    this.console = console
  }

  setLevel(logLevel: LogLevel) {
    this.level = mapLogLevel(logLevel)
  }

  context(prefix: string) {
    return new Logger(this.logLevel, `${this.prefix} ${prefix}`)
  }

  error(msg: string, data?: LogData | LogDataFunction) {
    this.log(1, msg, data)
  }

  warn(msg: string, data?: LogData | LogDataFunction) {
    this.log(2, msg, data)
  }

  info(msg: string, data?: LogData | LogDataFunction) {
    this.log(3, msg, data)
  }

  debug(msg: string, data?: LogData | LogDataFunction) {
    this.log(4, msg, data)
  }

  private log(level: number, msg: string, data?: LogData | LogDataFunction) {
    if (this.level >= level) {
      let message = msg
      if (data) {
        const param = typeof data === "function" ? data() : data
        message += " " + (typeof param === "string" ? param : JSON.stringify(param, null, 2))
      }
      this.console.log(`[${level}]${this.prefix ? ` ${this.prefix} |` : ""} ${message}`)
    }
  }
}
