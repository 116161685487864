import { type ControllerRenderProps } from "react-hook-form"

import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx"
import { Switch } from "@/components/ui/switch.tsx"

export type SwitchFieldProps = {
  field: ControllerRenderProps<any, any>
  description?: string
  label: string
  onCheckedChanged?: (checked: boolean) => void
}

export function SwitchField(props: SwitchFieldProps) {
  return (
    <FormItem>
      <div className="flex items-center justify-between gap-8">
        <div className="space-y-1">
          <FormLabel htmlFor={props.field.name}>{props.label}</FormLabel>
          {props.description && <FormDescription>{props.description}</FormDescription>}
        </div>
        <FormControl>
          <Switch
            ref={props.field.ref}
            checked={props.field.value}
            name={props.field.name}
            onBlur={props.field.onBlur}
            type="button"
            onCheckedChange={(checked) => {
              props.onCheckedChanged?.(checked)
              props.field.onChange(checked)
            }}
          />
        </FormControl>
      </div>
      <FormMessage />
    </FormItem>
  )
}
