import { ChatBotForm } from "@/components/chatbot-form.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import { Loader2 } from "lucide-react"
import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"

export async function chatBotEditPageLoader({ params }: LoaderFunctionArgs) {
  const account = authenticateGuard()
  invariant(account.role === "admin", "Account is required")
  invariant(params.chatBotId, "chatBotId is required")
  let chatBot = await trpc.chatbots.getBySlug.query({ slug: params.chatBotId })
  if (!chatBot) {
    chatBot = await trpc.chatbots.get.query({ chatBotId: params.chatBotId })
  }
  invariant(chatBot, "Chat Bot not found")
  return { chatBot }
}

type LoaderData = Awaited<ReturnType<typeof chatBotEditPageLoader>>

export function ChatBotEditPage() {
  const data = useLoaderData() as LoaderData

  const navigate = useNavigate()

  return (
    <>
      <H1 className="mb-4">Update Chatbot</H1>
      <Lead className="mb-8 text-balance">Update the ChatBot below.</Lead>
      <ChatBotForm
        chatBot={data.chatBot}
        onSubmit={async (values) => {
          await trpc.chatbots.update.mutate({
            id: data.chatBot.id,
            name: values.name,
            description: values.description,
            advancedReasoning: values.advancedReasoning,
            memory: values.memory,
            role: values.role,
            context: values.context,
            rules: values.rules,
            openingMessage: values.openingMessage,
            returnMessage: values.returnMessage,
            emailWhitelist: values.emailWhitelist,
            userMessageHandling: values.userMessageHandling,
            knowledgeIds: values.knowledge.map((k) => k.id),
            slug: values.slug,
          })
          // Chatbots show up in nav, so force navigate
          window.location.href = "/admin/chatbots"
        }}
        actions={(state) => (
          <>
            <div className="flex flex-grow justify-between">
              <div className="flex gap-3">
                <Button
                  type="submit"
                  variant="default"
                  disabled={state.isSubmitting || state.isLoading}
                >
                  {(state.isSubmitting || state.isLoading) && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Update ChatBot
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={state.isSubmitting || state.isLoading}
                  onClick={() => navigate("/admin/chatbots")}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Dialog>
                  <DialogTrigger asChild={true}>
                    <Button
                      type="button"
                      variant="destructive"
                      disabled={state.isSubmitting || state.isLoading}
                    >
                      Delete
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogTitle>Confirmation Needed</DialogTitle>
                    <DialogDescription>
                      You have requested that this ChatBot be removed from our system. This action
                      cannot be reversed. Please confirm the deletion below, or cancel if you wish
                      to keep the ChatBot.
                    </DialogDescription>
                    <DialogFooter>
                      <Button
                        variant="destructive"
                        disabled={state.isSubmitting || state.isLoading}
                        onClick={async () => {
                          await trpc.chatbots.remove.mutate({ chatBotId: data.chatBot.id })
                          // chatbots are in main nav, so force referesh
                          window.location.href = `/admin/chatbots`
                        }}
                      >
                        {(state.isSubmitting || state.isLoading) && (
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Confirm Delete
                      </Button>
                      <DialogClose asChild={true}>
                        <Button
                          disabled={state.isSubmitting || state.isLoading}
                          variant="secondary"
                        >
                          Cancel
                        </Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </div>

            <Card className="mt-8">
              <CardHeader>
                <CardTitle>Debugging</CardTitle>
                <CardDescription>
                  This section provides debugging information, which can be used by engineers to
                  help support issues with ChatBots.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid gap-6">
                <Button asChild={true} variant="secondary">
                  <a
                    href={`https://platform.openai.com/assistants/${data.chatBot.openaiAssistantId}`}
                    target="_blank"
                  >
                    View OpenAI Assistant
                  </a>
                </Button>
              </CardContent>
            </Card>
          </>
        )}
      />
    </>
  )
}
