import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ThemeProvider } from "./components/theme-provider.tsx"
import { TooltipProvider } from "./components/ui/tooltip.tsx"
import { AuthorizationProvider } from "./context/auth.tsx"
import "./global.css"
import { routes } from "./routes.tsx"

const router = createBrowserRouter(routes)

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <TooltipProvider>
        <AuthorizationProvider>
          <RouterProvider router={router} />
        </AuthorizationProvider>
      </TooltipProvider>
    </ThemeProvider>
  </React.StrictMode>,
)
