import { InputField } from "@/components/hook-form/input-field.tsx"
import { TextareaField } from "@/components/hook-form/textarea-field.tsx"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { Form, FormField } from "@/components/ui/form.tsx"
import { RouterOutput } from "@/lib/trpc.ts"
import { ErrorMessage } from "@hookform/error-message"
import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, useForm } from "react-hook-form"
import { z } from "zod"
import { GdriveFolderSelector } from "./gdrive-folder-selector.tsx"

type KnowledgeData = NonNullable<RouterOutput["knowledge"]["get"]>

export type KnowledgeFormProps = {
  data?: KnowledgeData
  actions: (state: { isSubmitting: boolean; isLoading: boolean }) => React.ReactNode
  onSubmit: (values: z.infer<typeof formSchema>) => any
}

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Please provide a name.",
  }),
  description: z.string().min(1, {
    message: "Please provide a name.",
  }),
  gdriveFolders: z
    .array(
      z.object({
        gdriveFolderId: z.string(),
        gdriveParentFolderId: z.string().nullable(),
        name: z.string(),
      }),
    )
    .min(1, {
      message: "Please select at least one folder.",
    }),
})

export function KnowledgeForm(props: KnowledgeFormProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: props.data?.knowledge?.name ?? "",
      description: props.data?.knowledge?.description ?? "",
      gdriveFolders: props.data?.sources ?? [],
    },
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(props.onSubmit)}>
        <div className="grid gap-6">
          <Card>
            <CardHeader>
              <CardTitle>Description</CardTitle>
              <CardDescription>Describe the knowledge, and its purpose.</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-6">
              <div className="grid gap-3">
                <FormField
                  control={form.control}
                  name="name"
                  disabled={form.formState.isSubmitting || form.formState.isLoading}
                  render={({ field }) => <InputField label="Name" field={field} />}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  control={form.control}
                  name="description"
                  disabled={form.formState.isSubmitting || form.formState.isLoading}
                  render={({ field }) => <TextareaField label="Description" field={field} />}
                />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Source Folders</CardTitle>
              <CardDescription>
                Select the folders that this Knowledge should be composed of.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Controller
                control={form.control}
                name="gdriveFolders"
                render={({ field }) => (
                  <>
                    <GdriveFolderSelector onChange={field.onChange} value={field.value} />

                    <div className="text-destructive text-sm">
                      <ErrorMessage errors={form.formState.errors} name="gdriveFolders" />
                    </div>
                  </>
                )}
              />
            </CardContent>
          </Card>

          {props.actions(form.formState)}
        </div>
      </form>
    </Form>
  )
}
